
export const  DEVICE_STATUS_POPUP_FAILURE = 'DEVICE_STATUS_POPUP_FAILURE';
export const  DEVICE_STATUS_POPUP_SUCCESS =  'DEVICE_STATUS_POPUP_SUCCESS';

export const  DEVICE_STATUS_MODEL_FAILURE = 'DEVICE_STATUS_MODEL_FAILURE';
export const  DEVICE_STATUS_MODEL_SUCCESS =  'DEVICE_STATUS_MODEL_SUCCESS';

export const  DEVICE_STATUS_DEBOARD_FAILURE = 'DEVICE_STATUS_DEBOARD_FAILURE';
export const  DEVICE_STATUS_DEBOARD_SUCCESS =  'DEVICE_STATUS_DEBOARD_SUCCESS';

export const  FETCH_MISSING_COMBINATION_SUCCESS = 'FETCH_MISSING_COMBINATION_SUCCESS';
export const  FETCH_MISSING_COMBINATION_FAILURE =  'FETCH_MISSING_COMBINATION_FAILURE';