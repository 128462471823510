
import  {DEVICE_STATUS_POPUP_FAILURE,DEVICE_STATUS_POPUP_SUCCESS,DEVICE_STATUS_MODEL_FAILURE,DEVICE_STATUS_MODEL_SUCCESS,DEVICE_STATUS_DEBOARD_SUCCESS,DEVICE_STATUS_DEBOARD_FAILURE,FETCH_MISSING_COMBINATION_SUCCESS,FETCH_MISSING_COMBINATION_FAILURE } from './DeviceShadowType';

export const checkPopupDeviceStatus = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case DEVICE_STATUS_POPUP_FAILURE:
        case DEVICE_STATUS_POPUP_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}



export const checkModelDeviceStatus = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case DEVICE_STATUS_MODEL_FAILURE:
        case DEVICE_STATUS_MODEL_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const checkDeboardDeviceStatus = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case DEVICE_STATUS_DEBOARD_FAILURE:
        case DEVICE_STATUS_DEBOARD_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

export const fetchMissingCombinationSlice = (  state = { isFetching: false, data: [] } , { type ,payload }= {}) => {
    switch (type) {
        case FETCH_MISSING_COMBINATION_FAILURE:
        case FETCH_MISSING_COMBINATION_SUCCESS:
            return ({}, payload);

        default:
            return state;
    }
}

